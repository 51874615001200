body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

blockquote{
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid orange; /* Just change the color value and that's it*/
}

.main-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 40px;
}

.sticky-nav {
  position: fixed;
  z-index: 999;
}

.list-inline li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-bold {
  font-weight: bold;
}

.card-img {
  object-fit: contain;
  width: 300px;
  max-height:400px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.profile-img {
  margin-top : 50px;
  object-fit: cover;
  width:300px;
  height:300px;
  overflow: hidden;
}

.slider .slides {
  width: 100%;
  height: 100%;
}

.slider .slides .project-img {
  /* max-width: 500px; */
  width: auto;
  margin: auto auto;
}

.small-img {
  object-fit: contain;
  width:80px;
  height:80px;
  overflow: hidden;
  margin-right: 20px;
}

.skill-img {
  object-fit: contain;
  width:120px;
  height:80px;
  overflow: hidden;
  margin: 10px
}

.theme-bg {
  background: #37474f;
}

.margin-20 {
  padding: 10px;
}

.margin-40 {
  margin-top : 40px;
}

.hover-highlight :hover{
  color: lightgrey;
}

.padding-top-left-20 {
  padding-top: 20px;
  padding-left: 20px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-40 {
  padding-top: 40px;
}

.padding-80 {
  padding: 80px;
}

.test {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.curved-edges {
  border-radius: 8px;
}

.rounded-edges {
  border-radius: 25px;
}